// CarList.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';


function CarList() {
    const [cars, setCars] = useState([]);
    

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await axios.get('https://freetestapi.com/api/v1/cars');
                setCars(response.data); // Assuming response.data is an array of car objects
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }

        fetchData();
    }, []);

    return (
        <div className="flex flex-col items-center justify-center min-h-screen m-5">
        <div className="max-w w-full">
       
        <div className="Container ">

            {/* Check if cars array is not empty before mapping */}
            {cars.length > 0 && (
                <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 w-full mt-5 mb-10">
                    {cars.map((car, index) => (
                        <div key={index} className="max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg rounded overflow-hidden shadow-lg bg-stone-900">
                            {/* Use car.image for the image source if available */}
                            <img className="w-full" src="https://images.opumo.com/wordpress/wp-content/uploads/2023/09/lotus.png" alt="Car" />
                            <div className="px-6 py-4">
                                <div className="flex justify-end">
                                    <a href="/" className="star-rating toggle-icons">
                                        <span className="icon icon-default"><i className="fas fa-star fa-fw fa-x"></i></span>
                                        <span className="icon icon-hover"><i className="far fa-star fa-fw fa-x"></i></span>
                                    </a>
                                </div>
                                <div className="font-bold text-xl mb-2 text-gray-100">{car.model}</div>
                                <p className="text-gray-100 font-bold text-base">{car.price}</p>
                                <div className="text-base mb-2 text-gray-100">{car.features}</div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            {/* Show a loading message or spinner while fetching data */}
            {cars.length === 0 && <p>Loading...</p>}
           
        </div>
        
        </div>
        
        </div>
        
      
         
    );
}

export default CarList;
