import React, { useState } from 'react';
import { CiDeliveryTruck } from "react-icons/ci";
import CarList from './CarList';
import SocialMedia from './SocialMedia';
import Footer from './Footer';


// Static image URLs for testing
const staticImages = [
    "https://pics.craiyon.com/2023-06-01/e800c31e299846f08302f501549cc278.webp",
    "https://auto.mahindra.com/-/media/project/mahindra/dotcom/mahindra/dark-theme-mahindra-images/hello-adrenox-carousel/thar/img_1.jpg?h=660&w=1360&rev=b32554f54aa34cf88f7865feca0b97ff&extension=webp",
    "https://auto.mahindra.com/-/media/project/mahindra/dotcom/mahindra/dark-theme-mahindra-images/hello-adrenox-carousel/thar/img_3.jpg?h=660&w=1360&rev=99ca6026bd594c6089f4cd96eab69355&extension=webp",
    "https://pics.craiyon.com/2023-06-01/e800c31e299846f08302f501549cc278.webp",
    "https://auto.mahindra.com/-/media/project/mahindra/dotcom/mahindra/dark-theme-mahindra-images/gallery/thar/desktop/mahindra-thar-snow-drive.png?rev=529a278415ec43ee898aa5333ddc0a0e&extension=webp",
    "https://pics.craiyon.com/2023-06-01/e800c31e299846f08302f501549cc278.webp",
    "https://auto.mahindra.com/-/media/project/mahindra/dotcom/mahindra/dark-theme-mahindra-images/gallery/thar/desktop/thar-headlight-view.jpg?rev=5be994b0336b426597d40c2b97bf9790&extension=webp",
    "https://auto.mahindra.com/-/media/project/mahindra/dotcom/mahindra/dark-theme-mahindra-images/hello-adrenox-carousel/thar/img_3.jpg?h=660&w=1360&rev=99ca6026bd594c6089f4cd96eab69355&extension=webp",
    "https://pics.craiyon.com/2023-06-01/e800c31e299846f08302f501549cc278.webp",
    "https://pics.craiyon.com/2023-06-01/e800c31e299846f08302f501549cc278.webp",
    "https://auto.mahindra.com/-/media/project/mahindra/dotcom/mahindra/dark-theme-mahindra-images/hello-adrenox-carousel/thar/img_3.jpg?h=660&w=1360&rev=99ca6026bd594c6089f4cd96eab69355&extension=webp",
    "https://auto.mahindra.com/-/media/project/mahindra/dotcom/mahindra/dark-theme-mahindra-images/gallery/thar/desktop/mahindra-thar-snow-drive.png?rev=529a278415ec43ee898aa5333ddc0a0e&extension=webp",
    "https://auto.mahindra.com/-/media/project/mahindra/dotcom/mahindra/dark-theme-mahindra-images/gallery/thar/desktop/mahindra-thar-snow-drive.png?rev=529a278415ec43ee898aa5333ddc0a0e&extension=webp",
    "https://pics.craiyon.com/2023-06-01/e800c31e299846f08302f501549cc278.webp"
];

const Gallery = () => {
    const [showMore, setShowMore] = useState(false);

    return (
        <>
         <div className="flex flex-col items-center justify-center min-h-screen m-10">
        <div className="max-w w-full">
            <SocialMedia />
            <div className="container  mb-5">

                <div className="max-w-screen-xl mx-auto  p-5 sm:p-10 md:p-5 relative">
                    <div className="grid grid-cols-4 grid-rows-2 gap-1">
                        <div className="col-span-2 row-span-3 sm:hidden md:block lg:block">
                            {/* First Image (2 col-span, 3 row-span) */}
                            <div className="h-[439px] col-span-2 bg-gray-200">
                                <img src={staticImages[0]} alt="gallery" className="h-full w-full object-cover" />
                            </div>
                        </div>
                        {/* Remaining Images */}
                        {staticImages.slice(1, showMore ? undefined : 7).map((image, index) => (
                            <div key={index} className="col-span-1 row-span-1">
                                <div className="h-36 bg-gray-200">
                                    <img src={image} alt="gallery" className="h-full w-full object-cover" />
                                </div>
                            </div>
                        ))}
                        {/* Show More Button */}
                        <div className="col-span-1 row-span-1">
                            <div className="h-36 bg-gray-200 flex justify-center items-center cursor-pointer" onClick={() => setShowMore(!showMore)}>
                                <span>{showMore ? 'Show Less' : 'All Photos'}</span>

                            </div>
                        </div>
                        {/* Additional Image in Last Column if Show More is Enabled */}
                        {showMore && (
                            <div className="col-span-1 row-span-1">
                                <div className="h-36 bg-gray-200">
                                    <img src={staticImages[9]} alt="gallery" className="h-full w-full object-cover" />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="grid grid-cols-4 gap-8 py-4">
                    <div className=" sm:col-span-4 md:col-span-3 lg:col-span-3 xl:col-span-3">
                        <div className="flex mb-4 rounded sm:col-span-3 md:col-span-2 lg:col-span-1 xl:col-span-1">
                            <div className="w-1/5 bg-stone-800 h-12 flex items-center justify-center text-white sm:hidden md:block lg:block">&#128336; Time Left 6 Days</div>
                            <div className="w-1/5 bg-stone-800 h-12 flex items-center justify-center text-white md:hidden lg:hidden sm:block">&#128336; 6 Days</div>
                            <div className="w-1/5 bg-stone-800 h-12 flex items-center justify-center text-white sm:hidden md:block lg:block">&uarr; High Bid $12,500</div>
                            <div className="w-1/5 bg-stone-800 h-12 flex items-center justify-center text-white sm:block md:hidden lg:hidden"> Bid $12,500</div>
                            <div className="w-1/5 bg-stone-800 h-12 flex items-center justify-center text-white sm:hidden md:block lg:block"># Bids 6</div>
                            <div className="w-1/5 bg-stone-800 h-12 flex items-center justify-center text-white sm:hidden md:hidden lg:block">&#128172; Comments 0</div>
                            <button className="bg-green-500 hover:bg-green-600 text-white font-base py-2 px-4 border border-green-600 rounded ml-4  sm:hidden md:hidden lg:block">
                                Place Bid
                            </button>
                        </div>
                        <div className="flex mb-4 rounded">
                            <div className="w-1/5 h-12 flex items-center justify-start text-white">&#128336; Time Left 6 Days</div>
                            <div className="w-1/5 h-12 flex items-center text-white justify-end">&uarr; High Bid $12,500</div>


                        </div>
                        <div className="grid lg:grid-cols-4 md:grid-cols-2 sm:grid-2 grid-cols gap-0 text-white mb-10 ">
                            <div className="border px-4 py-2">Make</div>
                            <div className="border px-4 py-2">Tesla</div>
                            <div className="border px-4 py-2">Model</div>
                            <div className="border px-4 py-2">Model S</div>

                            <div className="bg-stone-900 border px-4 py-2">Save</div>
                            <div className="bg-stone-900 border px-4 py-2">Mileage</div>
                            <div className="bg-stone-900 border px-4 py-2">62,200</div>
                            <div className="bg-stone-900 border px-4 py-2">VIN</div>

                            <div className="border px-4 py-2">5YJSA1H40FF086472</div>
                            <div className="border px-4 py-2">Title Status</div>
                            <div className="border px-4 py-2">Clean (MD)</div>
                            <div className="border px-4 py-2">Location</div>

                            <div className="bg-stone-900 border px-4 py-2">Bohemia, NY 11716</div>
                            <div className="bg-stone-900 border px-4 py-2">Seller</div>
                            <div className="bg-stone-900 border px-4 py-2">LICarsDirect</div>
                            <div className="bg-stone-900 border px-4 py-2">Engine</div>

                            <div className="border px-4 py-2">Dual Electric Motors</div>
                            <div className="border px-4 py-2">Drivetrain</div>
                            <div className="border px-4 py-2">4WD/AWD</div>
                            <div className="border px-4 py-2">Transmission</div>

                            <div className="bg-stone-900 border px-4 py-2">Automatic</div>
                            <div className="bg-stone-900 border px-4 py-2">Body Style</div>
                            <div className="bg-stone-900 border px-4 py-2">Sedan</div>
                            <div className="bg-stone-900 border px-4 py-2">Exterior Color</div>

                            <div className="border px-4 py-2">Midnight Silver Metallic</div>
                            <div className="border px-4 py-2">Interior Color</div>
                            <div className="border px-4 py-2">Black</div>
                            <div className="border px-4 py-2">Seller Type</div>

                            <div className="bg-stone-900 border px-4 py-2">Dealer</div>
                            <div className="bg-stone-900 border px-4 py-2">LICarsDirect</div>
                            <div className="bg-stone-900 border px-4 py-2"></div>
                            <div className="bg-stone-900 border px-4 py-2"></div>
                        </div>


                        <a href="/" className="block max-w p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 ">
                            <div className="flex items-center justify-between">
                                <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                                    <img src="https://experteditor.com.au/wp-content/uploads/2023/07/signs-youre-a-great-person-1024x538.png" alt="Profile" className="h-10 w-10 rounded-full" />Noteworthy technology acquisitions 2021</h5>

                            </div>
                            <p className="font-normal text-white dark:text-gray-400 ">The Tesla Model S is a fantastic electric sedan with excellent technology, impressive performance, and great practicality. This particular Model S is a P85D model, so it boasts all-wheel drive and almost 700 horsepower – the strongest figure that Tesla offered at the time. This Model S also features some nice equipment like 19-inch wheels, a glass roof panel, heated front and rear seats, a heated steering wheel, Highway Autopilot, and Premium Connectivity – AND it comes with Free Unlimited Supercharging, which is a great benefit. Lastly, this Model S is offered with a clean, accident-free Carfax report showing just one owner from new, further enhancing its appeal.</p>
                        </a>
                        <h1 className='text-white font-bold mb-4 mt-4'>Highlights</h1>
                        <p className='text-white mb-4'>THIS... is a 2015 Tesla Model S P85D, finished in Midnight Silver Metallic with a black interior.</p>

                        <ul className='text-white text-base list-disc mb-5 ml-3'>
                            <li className='mb-4'>The attached Carfax history report shows no accidents or mileage discrepancies in this Tesla's past. It also shows that it has been registered to one owner since new.</li>
                            <li className='mb-4'>According to the build sheet provided in the gallery, factory equipment includes 19-inch wheels, power-folding mirrors, a glass roof panel, heated front and rear seats, a heated steering wheel, lane departure warning, Highway Autopilot, Premium Connectivity, Free Unlimited Supercharging, and more as detailed below. The seller reports no modifications.</li>
                            <li className='mb-4'>The dual-motor, all-wheel drive P85D was the highest-performance Model S variant at the time of its introduction in 2014. In addition to an "Insane Mode" that gave the car a claimed 0-60 mph time of just 3.2 seconds, the P85D also upgraded the Model S's interior with more features and higher-quality materials.</li>
                            <li className='mb-4'> Power comes from a pair of electric motors that draw electricity from an 85-kilowatt-hour lithium-ion battery pack to power all four wheels with 691 horsepower and 687 lb-ft of torque, according to third-party estimates. In 2015, the Model S P85D received a maximum driving range rating of 253 miles from the EPA.</li>

                        </ul>
                        <h1 className='text-white font-bold mb-4'>Equipment</h1>
                        <p className='text-white mb-4'>A build sheet is provided in the gallery and a partial list of notable equipment includes:</p>
                        <ul className='text-white text-base list-disc mb-5 ml-3'>
                            <li className='mb-1'>Red-painted brake calipers</li>
                            <li className='mb-1'>Glass roof panel</li>
                            <li className='mb-1'>Power tailgate</li>
                            <li className='mb-1'>Heated and power-adjustable front seats</li>
                            <li className='mb-1'>Heated rear seats</li>
                            <li className='mb-1'>Heated steering wheel</li>
                            <li className='mb-1'>Dual-zone climate control</li>
                            <li className='mb-1'>17-inch center touchscreen</li>
                            <li className='mb-1'>Navigation system</li>
                            <li className='mb-1'>Lane departure warning</li>
                            <li className='mb-1'>Highway Autopilot</li>
                            <li className='mb-1'>Free Unlimited Supercharging</li>
                            <li className='mb-1'>Premium Connectivity</li>
                        </ul>

                        <h1 className='text-white font-bold mb-4'>Modifications</h1>
                        <p className='text-white mb-4'>Modifications reported by the seller include:</p>
                        <ul className='text-white text-base list-disc mb-5 ml-3'>
                            <li className='mb-1'>17-inch Enkei RPF1 wheels</li>
                            <li className='mb-1'>AEM air intake system</li>
                            <li className='mb-1'>Stubby antenna</li>
                            <li className='mb-1'>Black fuel door</li>
                            <li className='mb-1'>Pioneer DMH-1500NEX touchscreen head unit</li>
                            <li className='mb-1'>Rear-view camera</li>
                            <li className='mb-1'>Aftermarket shift knob</li>
                            <li className='mb-1'>Aftermarket cigarette lighter</li>
                        </ul>

                        <h1 className='text-white font-bold mb-4'>Known Flaws</h1>
                        <p className='text-white mb-4'>A Lemon Squad pre-purchase inspection is included with this listing. The following flaws are additionally pictured in the gallery:</p>
                        <ul className='text-white text-base list-disc mb-5 ml-3'>
                            <li className='mb-1'>Paint chips and scratches around the exterior</li>
                            <li className='mb-1'>Misaligned front bumper lip</li>
                            <li className='mb-1'>Hazy headlight lenses</li>
                            <li className='mb-1'>Bent corners of the hood near the hinges</li>
                            <li className='mb-1'>Curb rash on the wheels; passenger-side wheels are bent</li>
                            <li className='mb-1'>Wear on the driver's seat</li>
                            <li className='mb-1'>Some wear on interior touch points and trim panels; missing driver's door grab handle</li>
                            <li className='mb-1'>Some corrosion on underbody components</li>
                            <li className='mb-1'>The Lemon Squad inspector notes that the brakes feel spongy and that some noise was heard but that braking performance is unaffected, and also recommends replacing the wipers and upper hoses.</li>

                        </ul>


                        <h1 className='text-white font-bold mb-4'>Recent Service History</h1>
                        <p className='text-white mb-4'>The attached Carfax history report shows that the following recent services have been performed:</p>
                        <ul className='text-white text-base list-disc mb-5 ml-3'>
                            <li className='mb-1'>April 2023 (38,711 miles): Engine oil and filter changed</li>
                            <li className='mb-1'>August 2022 (36,957 miles): Engine oil and filter changedp</li>
                            <li className='mb-1'>September 2021 (34,662 miles): Engine oil and filter changed</li>
                            <li className='mb-1'>April 2021 (31,126 miles): Engine oil and filter changed, brake fluid, transmission fluid, and coolant changed, tires rotated</li>
                            <li className='mb-4'>COctober 2020 (28,957 miles): Engine oil and filter changed, battery, front brake pads, and steering angle sensor replaced</li>

                            <p className='text-white mb-4'>Additional service history is detailed in the attached Carfax report.</p>
                        </ul>

                        <h1 className='text-white font-bold mb-4'>Ownership History</h1>
                        <ul className='text-white text-base list-disc mb-5 ml-3'>
                            <li className='mb-1'>2 keys</li>
                            <li className='mb-1'>Owner's manual</li>

                        </ul>
                        <h1 className='text-white font-bold mb-4'>Other Items Included in Sale</h1>
                        <ul className='text-white text-base list-disc mb-5'>

                            <p className='text-white mb-4'>The seller purchased this Miata in March 2021.</p>
                        </ul>

                        <h1 className='text-white font-bold mb-4'>OSeller Notes</h1>
                        <ul className='text-white text-base list-disc mb-5'>

                            <p className='text-white mb-4'>Due to the modifications performed to this Miata, it may not pass emissions testing in some states. As always, it's the <b>buyer's responsibility</b> to perform all due diligence regarding registering this Miata in their respective state <b>prior to placing a bid.</b></p>
                        </ul>
                        <h1>Video</h1>
                        <video width="640" height="360" controls>
                            <source src="movie.mp4" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        <div className="grid grid-cols-6 gap-4 mb-4">

                            <div className=" flex col-start-1 col-end-3 text-2xl sm:text-base text-white font-bold">Seller Q&A (0)</div>
                            <div className="col-end-7 col-span-2 text-xl sm:text-base text-white flex justify-start">Ask a question</div>

                        </div>
                        <div className="shadow sm:shadow-md md:shadow-lg lg:shadow-xl xl:shadow-2xl border border-stone-900  h-24 flex justify-center items-center mb-4 rounded-[10px]">
                            <button className="bg-stone-900 border hover:text-black hover:bg-gray-300 text-white font-bold py-2 px-4 rounded">
                                Ask a question
                            </button>
                        </div>
                        <div className="shadow sm:shadow-md md:shadow-lg lg:shadow-xl xl:shadow-2xl bg-green-950 hover:bg-green-900 border border-stone-900 h-24 flex items-center justify-between px-5 mb-4 rounded-[10px]">
                            <div className="flex items-center justify-start">
                                <CiDeliveryTruck style={{ height: "50px", width: "50px" }} />


                                <div className="lg:flex justify-center ">
                                    <p className="text-2xl text-white font-bold flex justify-center ml-12 sm:text-base"><b>Cars & Bids Shipping</b>
                                    </p>
                                    <p className="text-base text-white font-normal ml-12 flex justify-center">Tap here to quote!</p>
                                </div>
                            </div>

                            <button className="bg-stone-900 border hover:text-black hover:bg-gray-300 text-white font-bold py-2  px-4  rounded justify-end sm:hidden md:hidden lg:block">
                                Ask a question
                            </button>
                        </div>

                        <div className="text-white text-2xl font-bold mb-4 sm:text-xl">2014 Mazda MX-5 Miata Club · No reserve</div>
                        <div className="bg-black rounded-lg shadow-lg ">


                            <div className="grid grid-cols-3 mb-4">

                                <div className="p-4">
                                    <p className="text-white font-bold">Current Bid Twopumch232</p>
                                    <p className="text-white text-[50px] font-bold sm:text-[30px]">$7,600</p>
                                    <p className="text-white text-base">As low as $133 per month* </p>

                                </div>
                                <div className="p-4 flex justify-end">
                                    <ul className="text-white font-bold">
                                        <li>Seller</li>
                                        <li>Ending</li>
                                        <li>Bids</li>
                                        <li>Views</li>
                                    </ul>
                                </div>

                                <div className="p-4">
                                    <ul className="text-white font-normal">
                                        <li>Nico101 contact</li>
                                        <li>Sat, May 11 1:21 AM</li>
                                        <li># 7</li>
                                        <li>1,939</li>
                                    </ul>
                                </div>
                            </div>

                            <div class="bg-stone-800 rounded-b-lg p-4 mb-4 sm:hidden md:block lg:block">
                                <div class="grid grid-cols-1 sm:grid-cols-4">
                                    <button class="text-green-700 font-semibold py-2 px-2 border bg-stone-700 border-stone-900 hover:border-gray-100 rounded w-full sm:w-auto sm:col-start-1 sm:col-end-2">
                                        Place Bid
                                    </button>
                                    <a href="#buying-info" class="text-white hover:underline flex justify-center sm:col-start-2 sm:col-end-3">How buying works</a>
                                    <a href="#buying-info" class="text-white hover:underline flex justify-center sm:col-start-3 sm:col-end-4">Watch this auction</a>
                                    <a href="#buying-info" class="text-white hover:underline flex justify-center sm:col-start-4 sm:col-end-5">Notify me of NC Miatas</a>
                                </div>
                            </div>

                        </div>

                        <nav className="bg-stone-600 mb-4 rounded-[10px] sm:hidden md:block">
                            <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">

                                <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">Flowbite</span>

                                <div className="hidden w-full md:block md:w-auto" id="navbar-default">
                                    <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 rounded-lg md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0">
                                        <li>
                                            <a href="/" className="block py-2 px-3 text-stone-100 rounded   md:p-0 dark:text-stone-900  dark:hover:text-white">Home</a>

                                        </li>
                                        <li>
                                            <a href="/" className="block py-2 px-3 text-stone-100 rounded   md:p-0 dark:text-stone-900  dark:hover:text-white">About</a>
                                        </li>
                                        <li>
                                            <a href="/" className="block py-2 px-3 text-stone-100 rounded   md:p-0 dark:text-stone-900  dark:hover:text-white">Services</a>
                                        </li>
                                        <li>
                                            <a href="/" className="block py-2 px-3 text-stone-100 rounded   md:p-0 dark:text-stone-900  dark:hover:text-white">Pricing</a>
                                        </li>
                                        <li>
                                            <a href="/" className="block py-2 px-3 text-stone-100 rounded   md:p-0 dark:text-stone-900  dark:hover:text-white">Contact</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </nav>
                        <div className="grid grid-cols-5 gap-2">
                            <button class="bg-stone-900 hover:bg-blue-700 text-white font-bold py-2 px-4 w-full rounded-full mb-4 col-span-2  text-[10px] lg:hidden md:hidden sm:block">
                                Watch auction
                            </button>
                            <button class="bg-stone-900 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded-full mb-4 col-span-3 text-[10px] lg:hidden md:hidden sm:block">
                                Notify me of Vantages
                            </button></div>

                        <form >
                            <label for="chat" className="sr-only mb-4 ">Your message</label>
                            <div className="flex items-center px-3 py-2 rounded-lg bg-gray-50 dark:bg-stone-900">
                                <button type="button" className="inline-flex justify-center p-2 text-stone-900 rounded-lg cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600">
                                    <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 18">
                                        <path fill="currentColor" d="M13 5.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0ZM7.565 7.423 4.5 14h11.518l-2.516-3.71L11 13 7.565 7.423Z" />
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18 1H2a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1Z" />
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 5.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0ZM7.565 7.423 4.5 14h11.518l-2.516-3.71L11 13 7.565 7.423Z" />
                                    </svg>
                                    <span className="sr-only">Upload image</span>
                                </button>
                                <button type="button" className="p-2 text-gray-500 rounded-lg cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600">
                                    <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.408 7.5h.01m-6.876 0h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0ZM4.6 11a5.5 5.5 0 0 0 10.81 0H4.6Z" />
                                    </svg>
                                    <span className="sr-only">Add emoji</span>
                                </button>
                                <textarea id="chat" rows="1" className="block mx-4 p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-stone-900 focus:border-blue-500 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-stone-900 dark:focus:border-stone-900" placeholder="Your message..."></textarea>
                                <button type="submit" className="inline-flex justify-center p-2 text-stone-900 rounded-full cursor-pointer hover:bg-blue-100 dark:text-blue-500 dark:hover:bg-gray-600">
                                    <svg className="w-5 h-5 rotate-90 rtl:-rotate-90" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                                        <path d="m17.914 18.594-8-18a1 1 0 0 0-1.828 0l-8 18a1 1 0 0 0 1.157 1.376L8 18.281V9a1 1 0 0 1 2 0v9.281l6.758 1.689a1 1 0 0 0 1.156-1.376Z" />
                                    </svg>
                                    <span className="sr-only">Send message</span>
                                </button>
                            </div>
                        </form>

                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4 sm:hidden md:block lg:block mt-5 mb-10">
                        <h1 className='text-white'> Auction ending soon 
              
                        <CarList className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4 w-full mt-5 mb-10" />
                    </h1>

                    </div>

                </div>
                <Footer />
            </div>
            </div>
                </div>
        </>

    );
};

export default Gallery;
