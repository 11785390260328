import React from 'react';
import './NavBar.css';

export default function NavBar() {
  return (
    <div className="contaner max-auto">
    <nav className="flex items-center justify-between flex-wrap bg-stone-900 p-6 ">
      <div className="flex items-center flex-shrink-0 text-white mr-6">
        <a href="/components/home" className="navbar-item text-2xl font-bold list-none">cars<span className="text-green-600"> & </span>bids</a>
      </div>
      <div className="block lg:hidden">
        <button className="flex items-center px-3 py-2 border rounded text-teal-200 border-teal-400 hover:text-white hover:border-white">
          <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" /></svg>
        </button>
      </div>
      <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
        <div className="text-sm lg:flex-grow">
          <a href="/components/home" className="block mt-4 font-semibold lg:inline-block lg:mt-0 text-slate-400 hover:text-white mr-4">Auctions</a>
          <a href="/components/gallery" className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4"><button className="bg-green-600 hover:bg-green-700 text-white font-semibold py-2 px-4 rounded-full">
            Sell a car
          </button></a>
          <a href="/components/carlist" className="block mt-4 font-semibold lg:inline-block lg:mt-0 text-slate-400 hover:text-white mr-4">Community</a>
          <a href="/components/carlist" className="block mt-4 font-semibold lg:inline-block lg:mt-0 text-slate-400 hover:text-white mr-4">What’s Cars & Bids?</a>
          <a href="/components/carlist" className="block mt-4 font-semibold lg:inline-block lg:mt-0 text-slate-400 hover:text-white mr-4">Daily Email</a>
        </div>
        <form className="max-w-md mx-auto lg:w-auto">
          <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
              </svg>
            </div>
            <input type="search" id="default-search" className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-stone-800 focus:ring-blue-500 focus:border-blue-500 dark:bg-stone-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 mr-20" placeholder="Search for car (ex. BMW, Audi, Ford)" required />
          </div>
        </form>
        <div>
          <a href="/components/SocialMedia" className="bg-green-600 hover:bg-green-700 text-white font-semibold py-2 px-4 rounded-full ml-20">Sign UP</a>
        </div>
      </div>
    </nav>
    </div>
  );
}
