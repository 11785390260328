
import './Home.css';
import CarsItem from './CarsItem';
import Footer from './Footer';
import React, { useState, useEffect, useRef } from 'react';
import ImageSlider from './ImageSlider';


function Home() {
    const [carsData, setCarsData] = useState([]);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        // Fetch data from the API endpoint
        fetch('https://freetestapi.com/api/v1/cars')
            .then(response => response.json())
            .then(data => {
                // Update state with the fetched data
                setCarsData(data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });

        // Add event listener to detect clicks outside of dropdown
        document.addEventListener('click', handleClickOutside);

        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []); // Empty dependency array to run only once

    const toggleDropdown = () => {
        setIsDropdownVisible(!isDropdownVisible);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsDropdownVisible(false);
        }
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen">
            <div className="max-w w-full">

                <div className="container mx-auto relative">

                    <ImageSlider />


                    <div class="flex flex-col md:flex-row ">
                        <div class="basis-1/6 text-white">
                            <h1 className="gap-4 text-white text-2xl font-bold">Auctions</h1>
                        </div>
                        <div class="basis-6/12 ">
                            <button type="button" class="text-white bg-stone-950 border border-gray-500 hover:bg-gray-900 focus:outline-none focus:ring-1 focus:ring-stone-800 font-base rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-stone-950 dark:hover:bg-stone-900 dark:focus:ring-stone-300 dark:border-gray ">Years</button>

                            <div className="relative inline-block" ref={dropdownRef}>
                                <button
                                    id="dropdownDefaultButton"
                                    onClick={toggleDropdown}
                                    className="text-white bg-stone-950 border border-gray-500 focus:ring-1 focus:outline-none focus:ring-stone-800 font-base rounded-lg text-sm px-5 py-2.5 me-2 mb-2 text-center inline-flex items-center dark:bg-stone-950 dark:border-dark-gray dark:focus:ring-stone-300 hover:bg-stone-900 hover:text-white"
                                    type="button"
                                >
                                    Transmission
                                    <svg
                                        className="w-2.5 h-2.5 ms-3"
                                        aria-hidden="true"
                                        // xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 10 6"
                                    >
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                                    </svg>
                                </button>
                                {isDropdownVisible && (
                                    <div
                                        id="dropdown"
                                        className="absolute left-0 mt-1 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-stone-950 z-10"
                                        style={{ top: "100%", zIndex: 999 }}
                                    >
                                        <ul className="py-2 text-sm text-gray-900 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
                                            <li>
                                                <a href="/" className="block px-4 py-2 hover:bg-stone-900 dark:hover:bg-stone-900 dark:hover:text-white">All</a>
                                            </li>
                                            <li>
                                                <a href="/" className="block px-4 py-2 hover:bg-stone-900 dark:hover:bg-stone-950dark:hover:text-white">Automatuc</a>
                                            </li>
                                            <li>
                                                <a href="/" className="block px-4 py-2 hover:bg-stone-900 dark:hover:bg-stone-950dark:hover:text-white">Manual</a>
                                            </li>
                                        </ul>
                                    </div>
                                )}
                            </div>

                            <button id="dropdownDefaultButton"
                                onClick={toggleDropdown}
                                className="text-white bg-stone-950 border border-gray-500 focus:ring-1 focus:outline-none focus:ring-stone-800 font-base rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-stone-950 dark:border-dark-gray dark:focus:ring-stone-300 hover:bg-stone-900 hover:text-white"
                                type="button">Body Style <svg class="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4" />
                                </svg>
                            </button>

                            {/* <!-- Dropdown menu --> */}
                            <div id="dropdown" class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
                                <ul class="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
                                    <li>
                                        <a href="/" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Coupe</a>
                                    </li>
                                    <li>
                                        <a href="/" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Convertible</a>
                                    </li>
                                    <li>
                                        <a href="/" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Hatchback</a>
                                    </li>
                                    <li>
                                        <a href="/" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Sedan</a>
                                    </li>
                                    <li>
                                        <a href="/" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">SUV/Crossover</a>
                                    </li>
                                    <li>
                                        <a href="/" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Truch</a>
                                    </li>
                                    <li>
                                        <a href="/" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Van/Minivan</a>
                                    </li>
                                    <li>
                                        <a href="/" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Wagon</a>
                                    </li>
                                </ul>
                            </div>


                        </div>

                        <div class="basis-6/12">
                            <div class="relative text-base font-normal text-slate-400 flex lg:justify-end gap-2 ">
                                <a href="/components/carlist" className="text-base font-normal hover-underline"> <span>Ending Soon</span></a>
                                <a href="/components/carlist" className="text-base font-normal hover-underline"> <span>Newly listed</span></a>
                                <a href="/" className="text-base font-normal hover-underline"> <span>No reserve</span></a>
                                <a href="/" className="text-base font-normal hover-underline"> <span>Lowest mileage</span></a>
                                <a href="/" className="text-base font-normal hover-underline"> <span>Closest to me</span></a>
                            </div>
                        </div>

                    </div>

                    <CarsItem carsData={carsData} />
                    <div className="flex justify-center items-center mt-[50px] mb-[50px]">
                        <button
                            type="button"
                            className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                        >
                            View Auction Results
                        </button>
                    </div>

                    <Footer />
                </div>
            </div>
        </div>


    );
}

export default Home;