import { GoShare } from "react-icons/go";
import React, { useState } from "react";
import { MdOutlineStarOutline } from "react-icons/md";

const SocialMedia = () => {
  const [isWatchPopupOpen, setIsWatchPopupOpen] = useState(false);
  const [isSharePopupOpen, setIsSharePopupOpen] = useState(false);

  const handleWatchClick = () => {
    setIsWatchPopupOpen(true);
  };

  const handleShareClick = () => {
    setIsSharePopupOpen(true);
  };

  const handleCloseWatchPopup = () => {
    setIsWatchPopupOpen(false);
  };

  const handleCloseSharePopup = () => {
    setIsSharePopupOpen(false);
  };

  return (
    <>
  <div className="flex items-center gap-4 justify-end overflow-x-hidden">
        <button
          className="bg-stone-900 hover:bg-stone-800 text-white font-bold py-2 px-4 rounded flex items-center"
          onClick={handleWatchClick}
        >
          <MdOutlineStarOutline className="mr-2" />
          Watch
        </button>

        <button
          className="bg-stone-900 hover:bg-stone-800 text-white font-bold py-2 px-4 rounded flex items-center"
          onClick={handleShareClick}
        >
          <GoShare className="mr-2" />
          Share
        </button>
      </div>

      {/* Watch Popup */}
      {isWatchPopupOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black/50">
          <div className="relative p-4 w-full max-w-md h-full md:h-auto">
            
            <div className="relative bg-white rounded-lg shadow">
            <button
  type="button"
  className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-stone-800 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center popup-close"
  onClick={handleCloseWatchPopup}
              >
                <svg
                  aria-hidden="true"
                  className="w-5 h-5"
                  fill="#c6c7c7"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="sr-only">Close popup</span>
              </button>
       

            <div class="p-5 bg-stone-900">
               
                <p class="mb-4 text-sm font-normal text-gray-800 "></p>

                <div class="text-center">
                    <p class="mb-3 text-2xl font-semibold leading-5 text-white">
                        Login to your account
                    </p>
                    <p class="mt-2 text-sm leading-4 text-green-600">
                        You must be logged in to perform this action.
                    </p>
                </div>

                <div class="mt-7 flex flex-col gap-2">

                <button class="inline-flex h-10 w-full items-center justify-center gap-2 rounded border border-slate-300 bg-white p-2 text-sm font-medium text-black outline-none focus:ring-2 focus:ring-[#333] focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-60">
    <img src="https://www.svgrepo.com/show/512317/github-142.svg" alt="GitHub" class="h-[18px] w-[18px]" />
    Continue with GitHub
</button>


                    <button
                        class="inline-flex h-10 w-full items-center justify-center gap-2 rounded border border-slate-300 bg-white p-2 text-sm font-medium text-black outline-none focus:ring-2 focus:ring-[#333] focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-60"><img
                            src="https://www.svgrepo.com/show/475656/google-color.svg" alt="Google"
                            class="h-[18px] w-[18px] " />
                            Continue with Google
                    </button>


                    <button
                        class="inline-flex h-10 w-full items-center justify-center gap-2 rounded border border-slate-300 bg-white p-2 text-sm font-medium text-black outline-none focus:ring-2 focus:ring-[#333] focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-60"><img
                            src="https://www.svgrepo.com/show/448234/linkedin.svg" alt="Google"
                            class="h-[18px] w-[18px] " />Continue with
                        LinkedIn
                    </button>
                </div>

                <div class="flex w-full items-center gap-2 py-6 text-sm text-white">
                    <div class="h-px w-full bg-stone-700"></div>
                    OR
                    <div class="h-px w-full bg-stone-700"></div>
                </div>


                <form class="w-full">
                    <label for="email" class="sr-only">Email address</label>
                    <input name="email" type="email" autocomplete="email" required=""
                        class="block bg-stone-900 w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm outline-none placeholder:text-gray-400 focus:ring-2 focus:ring-black focus:ring-offset-1"
                        placeholder="Email Address" value="" />
                    <label for="password" class="sr-only">Password</label>
                    <input name="password" type="password" autocomplete="current-password" required=""
                        class="mt-2 block bg-stone-900 w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm outline-none placeholder:text-gray-400 focus:ring-2 focus:ring-black focus:ring-offset-1"
                        placeholder="Password" value="" />
                    <p class="mb-3 mt-2 text-sm text-gray-500">
                        <a href="/forgot-password" class="text-blue-800 hover:text-green-600">Reset your password?</a>
                    </p>
                    <button type="submit"
                        class="inline-flex w-full items-center justify-center rounded-lg bg-black p-2 py-3 text-sm font-medium text-white outline-none focus:ring-2 focus:ring-black focus:ring-offset-1 disabled:bg-gray-400">
                        Continue
                    </button>
                </form>

                <div class="mt-6 text-center text-sm text-slate-500">
                    Don't have an account?
                    <a href="/signup" class="font-medium text-green-600">Sign up</a>
                </div>
            </div>
        </div>
    </div>
</div>

           
           
      )}

      {/* Share Popup */}
      {isSharePopupOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black/50">
          <div className="relative p-4 w-full max-w-md h-full md:h-auto">
            <div className="relative bg-white rounded-lg shadow">
              <button
                type="button"
                className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-stone-800 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center popup-close"
                onClick={handleCloseSharePopup}
              >
                <svg
                  aria-hidden="true"
                  className="w-5 h-5"
                  fill="#c6c7c7"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="sr-only">Close popup</span>
              </button>
                <div className="sharing-buttons grid grid-cols-1 mt-5 py-8 px-8 bg-stone-900 rounded">
                  <h1 className="text-2xl font-bold flex justify-center mb-5 text-white">Share this car</h1>
              <a class="border-2 duration-200 ease inline-flex items-center mb-1 mr-1 transition py-2 px-5 rounded-lg text-white border-green-600 bg-green-600 hover:bg-green-700 hover:border-green-700 flex justify-center" target="_blank" rel="noreferrer" href="https://facebook.com/sharer/sharer.php?u=" aria-label="Share on Facebook">
                <svg aria-hidden="true" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-6 h-6">
                  <title>Facebook</title>
                  <path d="M379 22v75h-44c-36 0-42 17-42 41v54h84l-12 85h-72v217h-88V277h-72v-85h72v-62c0-72 45-112 109-112 31 0 58 3 65 4z">
                  </path>
                </svg>
                <span class="ml-2">Facebook</span>
              </a>
              <a class="border-2 duration-200 ease inline-flex items-center mb-1 mr-1 transition py-2 px-5 rounded-lg text-white border-green-600 bg-green-600 hover:bg-green-700 hover:border-green-700 flex justify-center" target="_blank" rel="noreferrer" href="https://twitter.com/intent/tweet?url=&amp;text=" aria-label="Share on Twitter">
                <svg aria-hidden="true" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-6 h-6">
                  <title>Twitter</title>
                  <path d="m459 152 1 13c0 139-106 299-299 299-59 0-115-17-161-47a217 217 0 0 0 156-44c-47-1-85-31-98-72l19 1c10 0 19-1 28-3-48-10-84-52-84-103v-2c14 8 30 13 47 14A105 105 0 0 1 36 67c51 64 129 106 216 110-2-8-2-16-2-24a105 105 0 0 1 181-72c24-4 47-13 67-25-8 24-25 45-46 58 21-3 41-8 60-17-14 21-32 40-53 55z">
                  </path>
                </svg>
                <span class="ml-2">Twitter</span>
              </a>
              <a class="border-2 duration-200 ease inline-flex items-center mb-1 mr-1 transition py-2 px-5 rounded-lg text-white border-green-600 bg-green-600 hover:bg-green-700 hover:border-green-700 flex justify-center" target="_blank" rel="noreferrer" href="https://www.linkedin.com/shareArticle?mini=true&amp;url=&amp;title=&amp;summary=&amp;source=" aria-label="Share on Linkedin">
                <svg aria-hidden="true" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-6 h-6">
                  <title>Linkedin</title>
                  <path d="M136 183v283H42V183h94zm6-88c1 27-20 49-53 49-32 0-52-22-52-49 0-28 21-49 53-49s52 21 52 49zm333 208v163h-94V314c0-38-13-64-47-64-26 0-42 18-49 35-2 6-3 14-3 23v158h-94V183h94v41c12-20 34-48 85-48 62 0 108 41 108 127z">
                  </path>
                </svg>
                <span class="ml-2">Linkedin</span>
              </a>
            </div>
          </div>
        </div>
              </div>
            
         
      )}
    </>
  );
};

export default SocialMedia;
