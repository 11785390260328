import React, { useEffect, useState } from 'react';
import Home from './components/Home';
import Gallery from './components/Gallery';
import CarList from './components/CarList';
import NavBar from './components/NavBar';

function App() {
  const [path, setPath] = useState(window.location.pathname);

  useEffect(() => {
    const handlePathChange = () => {
      setPath(window.location.pathname);
    };

    window.addEventListener('popstate', handlePathChange);

    return () => {
      window.removeEventListener('popstate', handlePathChange);
    };
  }, []);

  let currentComponent = null;

  switch (path) {
    case '/components/home':
      currentComponent = <Home />;
      break;
    case '/components/gallery':
      currentComponent = <Gallery />;
      break;
    case '/components/carlist':
      currentComponent = <CarList />;
      break;
    default:
      currentComponent = <Home />;
      break;
  }

  return (
    <div className="bg-stone-900">
      <NavBar />
      {currentComponent}
    </div>
  );
}

export default App;
