import React, { useState, useEffect } from 'react';
import './CarsItem.css';
// import CarList from './CarList'

function CarsItem({ carsData }) {
    const [timeLeft, setTimeLeft] = useState('');

    useEffect(() => {
        const calculateTimeLeft = () => {
            const deadline = new Date('Jul 09, 2024 23:59:59').getTime(); // Change this to your deadline
            const now = new Date().getTime();
            const difference = deadline - now;

            if (difference > 0) {
                const days = Math.floor(difference / (1000 * 60 * 60 * 24));
                const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((difference % (1000 * 60)) / 1000);

                setTimeLeft(`${days}d ${hours}h ${minutes}m ${seconds}s`);
            } else {
                setTimeLeft('Closed');
            }
        };

        const timer = setInterval(calculateTimeLeft, 1000);

        return () => clearInterval(timer);
    }, []);

    const chunkArray = (array, size) => {
        if (!Array.isArray(array)) {
            return [];
        }
        return Array.from({ length: Math.ceil(array.length / size) }, (_, index) =>
            array.slice(index * size, index * size + size)
        );
    };

    const chunkedCarsData = chunkArray(carsData, 4);

    return (
        <div className="mx-auto">
            {chunkedCarsData.map((row, rowIndex) => (
                <div key={rowIndex} className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-5 mb-10">
                    {row.map((car, index) => (
                        <div key={index} className="max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg relative overflow-hidden shadow-lg bg-stone-900 mx-auto"> {/* Added relative and mx-auto class */}
                            <img className="w-full" src={"https://www.rolls-roycemotorcars.com/content/dam/rrmc/marketUK/rollsroycemotorcars_com/phantom-series-ii-discover/page-components/Phantom-II-Discover-Full-Shot-1-R.jpg/jcr:content/renditions/original"} alt="Car" />
                            <div className="px-6 mt-[-30px] absolute center-0 left-0">
                                <span className="bg-zinc-900 text-white text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded me-2">
                                <svg className="w-2.5 h-2.5 me-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                        <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm3.982 13.982a1 1 0 0 1-1.414 0l-3.274-3.274A1.012 1.012 0 0 1 9 10V6a1 1 0 0 1 2 0v3.586l2.982 2.982a1 1 0 0 1 0 1.414Z"/>
                                    </svg>{timeLeft}</span>
                            </div>
                            <div className="px-6 py-4">
                                <div className="flex justify-end">
                                    <a href="/" className="star-rating toggle-icons">
                                        <span className="icon icon-default"><i className="fas fa-star fa-fw fa-x"></i></span>
                                        <span className="icon icon-hover"><i className="far fa-star fa-fw fa-x"></i></span>
                                    </a>
                                </div>
                                <div className="font-bold text-xl mb-2 text-gray-100">{car?.model}</div>
                                <p className="text-gray-100 font-bold text-base">{car?.price}</p>
                                <div className="text-base mb-2 text-gray-100">{car?.features}</div>
                            </div>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
}

export default CarsItem;
