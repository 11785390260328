import React, { useState, useEffect } from 'react';


const ImageSlider = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    // Define an array of image URLs
    const imageUrls = [
        "https://cdn.pixabay.com/photo/2023/03/23/07/56/ai-generated-7871392_1280.jpg",
        "https://www.rolls-roycemotorcars.com/content/dam/rrmc/marketUK/rollsroycemotorcars_com/phantom-series-ii-discover/page-components/Phantom-II-Discover-Full-Shot-1-R.jpg/jcr:content/renditions/original",
        "https://img.freepik.com/free-photo/close-up-fuel-level-gauge-vehicle_23-2150163662.jpg?w=996&t=st=1715075921~exp=1715076521~hmac=63ff05d4ac08fc97fefa65a4a72d6cacb6065ff72e278356a519df717c27e124",
        "https://img.freepik.com/free-photo/luxury-car-speeds-by-modern-building-dusk-generative-ai_188544-8048.jpg?w=996&t=st=1715075990~exp=1715076590~hmac=276f210335fbeb84b24a4ea622f14579d865298be8eaed778be0d1a214a13d9e",
        "https://img.freepik.com/free-photo/luxurious-car-parked-highway-with-illuminated-headlight-sunset_181624-60607.jpg?w=996&t=st=1715076056~exp=1715076656~hmac=3c1dc6341ce5b426ee6f5ea10f592863a0b9f12b08067c24908300812580eca0"
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % imageUrls.length);
        }, 2000);

        return () => clearInterval(interval);
    }, [imageUrls.length]);

    return (
        <div className="container w-full mt-0 mb-0">
            <div className="max-w-screen-xl mx-auto mt-0 p-5 sm:p-10 md:p-5 relative">
                <div className="grid grid-cols-4 grid-rows-2 gap-1" id="imageGrid">
                    <div className="col-span-2 row-span-2 md:col-span-3 lg:col-span-2 sm:hidden md:block lg:block relative">
                        <a href="/">
                            <div
                                className="h-[293px] bg-cover text-center overflow-hidden relative"
                                style={{ backgroundImage: `url('${imageUrls[currentIndex]}')` }}
                                
                            >
                                 <div className="font-bold text-xl mb-2 text-gray-100 absolute top-0 right-0 m-2">Car & Bids</div>
                                <span className="bg-gray-100 text-gray-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded absolute top-0 left-0 m-2">FEATURED</span>
                                <span className="bg-gray-100 text-gray-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded absolute bottom-0 left-0 m-2">
                                    <svg className="w-2.5 h-2.5 me-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                        <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm3.982 13.982a1 1 0 0 1-1.414 0l-3.274-3.274A1.012 1.012 0 0 1 9 10V6a1 1 0 0 1 2 0v3.586l2.982 2.982a1 1 0 0 1 0 1.414Z"/>
                                    </svg>
                                    6 days ago
                                </span>
                            </div>
                        </a>
                    </div>
                    {Array.from({ length: 5 }).map((_, index) => (
                        index !== 4 && (
                            <div key={index} className="col-span-1 row-span-1 sm:hidden md:block lg:block">
                                <a href="/">
                                    <div
                                        className="h-36 bg-cover text-center overflow-hidden"
                                        style={{ backgroundImage: `url('${imageUrls[currentIndex]}')` }}
                                    ></div>
                                </a>
                            </div>
                        )
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ImageSlider;
